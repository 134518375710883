import React, { useState, useEffect } from 'react';
import ProjectBox from '../components/ProjectsBox';

const GitHubRepos = () => {
  const [repos, setRepos] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const username = 'LucasDltg'; // Replace with the GitHub username
    const apiUrl = `https://api.github.com/users/${username}/repos`;
    const cacheKey = 'githubRepos';
    const cacheTTL = 10 * 60 * 1000; // 10 minutes in milliseconds

    // Clear all cached data in local storage
    // localStorage.clear();


    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const currentTime = new Date().getTime();

    // If cached data exists and is within the TTL, use it
    if (cachedData && cachedTimestamp && currentTime - cachedTimestamp <= cacheTTL) {
      setRepos(JSON.parse(cachedData));
    } else {
      // Fetch fresh data
      fetch(apiUrl)
        .then((response) => {
          if (response.status === 403) {
            // Handle 403 Forbidden error
            throw new Error('API rate limit exceeded. Please try again later.');
          }
          return response.json();
        })
        .then(async (data) => {
          // Store data in cache
          localStorage.setItem(cacheKey, JSON.stringify(data));
          localStorage.setItem(`${cacheKey}_timestamp`, currentTime);

          setRepos(data);
        })
        .catch((error) => {
          setError(error.message);
          console.error('Error fetching public GitHub repositories:', error);
        });
    }
  }, []);

  return (
    <div style={{ display: 'inherit', flexWrap: 'inherit', justifyContent: 'space-between', margin: '5% 5% 5% 5%', marginBottom: '100px'}}>
      {error ? (
        <p>{error}</p>
      ) : (
        repos.map((repo) => (
          <ProjectBox
            key={repo.id}
            name={repo.name}
            language={repo.language}
            stargazers={repo.stargazers_count}
            content={repo.description} // Use repo.description as content
            githubLink={repo.html_url}
          />
        ))
      )}
    </div>
  );
};

export default GitHubRepos;
