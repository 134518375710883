import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa';
import '../styles/Header.css';

const Header = () => {
  /*const handlePostRequest = () => {
    const ipAddress = '127.0.0.1'; // Adresse IP de destination
    const port = 5000; // Port de destination
    const route = '/api'; // Route de destination

    fetch(`http://${ipAddress}:${port}${route}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}) // Empty request body
    })
      .then(response => response.json())
      .then(data => {
        // Handle response data
      })
      .catch(error => {
        // Handle error
      });
  };*/

  return (
    <header className="header">
      <Link to="/" className="header-title">Lucas Deletang</Link>
      <div className="header-buttons">
        <Link to="/projects">Projects</Link>
        {/* <button onClick={handlePostRequest}>Send POST Request</button> Bouton pour envoyer la requête POST */}
        <Link to="/webgl">WebGL</Link>
      </div>
      <div className="header-icons">
        <a href="https://www.instagram.com/lucas_dltg/"><FaInstagram /></a>
        <a href="https://github.com/LucasDltg"><FaGithub /></a>
        <a href="https://www.linkedin.com/in/lucas-deletang/"><FaLinkedin /></a>
      </div>
    </header>
  );
};

export default Header;