// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between; /* Aligne les éléments à l'extrémité gauche et droite du conteneur (header) */
    align-items: center; /* Centre les éléments verticalement */
    padding: 10px 2em;
    border-bottom: solid 1px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #26292F;
    font-size: 1em;
  }
  
  .header-icons {
    display: flex;
    gap: 20px; /* Espacement entre les icônes */
  }
  
  .header-icons a {
    text-decoration: none; /* Supprime les soulignements des liens */
    font-size: 1.5em; /* Taille de la police des icônes */
    color: inherit;
  }
  
  .header-title {
    font-size: 1.2em;
    text-decoration: none;
    color: inherit;
    text-align: center;
  }

  .header-buttons {
    display: flex;
    gap: 1em;
  }
  
  .header-buttons a {
    text-decoration: none; /* Supprime les soulignements de lien */
    padding: 10px 20px; /* Espacement interne pour le texte du bouton */
    border-radius: 10px; /* Coins arrondis */
    color: inherit;
  }
  

  `, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B,EAAE,6EAA6E;IAC7G,mBAAmB,EAAE,sCAAsC;IAC3D,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,MAAM;IACN,UAAU;IACV,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,SAAS,EAAE,gCAAgC;EAC7C;;EAEA;IACE,qBAAqB,EAAE,yCAAyC;IAChE,gBAAgB,EAAE,mCAAmC;IACrD,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,QAAQ;EACV;;EAEA;IACE,qBAAqB,EAAE,uCAAuC;IAC9D,kBAAkB,EAAE,+CAA+C;IACnE,mBAAmB,EAAE,mBAAmB;IACxC,cAAc;EAChB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between; /* Aligne les éléments à l'extrémité gauche et droite du conteneur (header) */\n    align-items: center; /* Centre les éléments verticalement */\n    padding: 10px 2em;\n    border-bottom: solid 1px;\n    position: sticky;\n    top: 0;\n    z-index: 1;\n    background-color: #26292F;\n    font-size: 1em;\n  }\n  \n  .header-icons {\n    display: flex;\n    gap: 20px; /* Espacement entre les icônes */\n  }\n  \n  .header-icons a {\n    text-decoration: none; /* Supprime les soulignements des liens */\n    font-size: 1.5em; /* Taille de la police des icônes */\n    color: inherit;\n  }\n  \n  .header-title {\n    font-size: 1.2em;\n    text-decoration: none;\n    color: inherit;\n    text-align: center;\n  }\n\n  .header-buttons {\n    display: flex;\n    gap: 1em;\n  }\n  \n  .header-buttons a {\n    text-decoration: none; /* Supprime les soulignements de lien */\n    padding: 10px 20px; /* Espacement interne pour le texte du bouton */\n    border-radius: 10px; /* Coins arrondis */\n    color: inherit;\n  }\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
