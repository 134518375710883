// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project {
    display: flex;
    flex-direction: row; /* Arrange boxes horizontally */
    flex-wrap: wrap; /* Wrap boxes to the next line when they don't fit the width */
  }`, "",{"version":3,"sources":["webpack://./src/styles/Project.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,+BAA+B;IACpD,eAAe,EAAE,8DAA8D;EACjF","sourcesContent":[".project {\n    display: flex;\n    flex-direction: row; /* Arrange boxes horizontally */\n    flex-wrap: wrap; /* Wrap boxes to the next line when they don't fit the width */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
