import '../styles/App.css';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Photo from '../pages/Photo';
import WebGL from '../pages/WebGL/WebGL';
import Project from '../pages/Project'; // Corrected import
import Welcome from '../pages/Welcome';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Welcome />} />
          {/* <Route path="/photo" element={<Photo />} /> */}
          <Route path="/projects" element={<Project />} />
          <Route path="/webgl" element={<WebGL/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
