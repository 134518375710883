// ProjectBox.js
import React from 'react';
import { FaStar } from 'react-icons/fa';
import '../styles/ProjectBox.css';

const ProjectBox = (props) => {
  const { name, language, stargazers, content, githubLink } = props;

  // Create a variable to hold the language class
  let languageClass = '';

  // Conditionally set the language class based on the language value
  if (language === 'Python') {
    languageClass = 'python';
  } else if (language === 'JavaScript') {
    languageClass = 'javascript';
  } else if (language === 'Java') {
    languageClass = 'java';
  } else if (language === 'C') {
    languageClass = 'c';
  } else if (language === 'C++') {
    languageClass = 'cpp';
  } else {
    // Default case for other languages
    languageClass = 'other';
  }

  return (
    <a href={githubLink} target="_blank" rel="noopener noreferrer" className="project-box">
      <div className='name'>{name}</div>
      <div className={`language ${languageClass}`}>{language}</div>
      <div className="stars">
        <span>{stargazers}</span>
        <FaStar className='star'/>
      </div>
      <div className='description'>{content}</div>
    </a>
  );
};

export default ProjectBox;

// return (
//     <div className="project-box">
//       <div className='name'>{name}</div>
//       {Array.isArray(language) && language.length > 0 && (
//         <div className="languages">
//           {language.map((lang, index) => (
//             <div key={index} className={`language ${languageClass}`}>
//               {lang}
//             </div>
//           ))}
//         </div>
//       )}
//       <div className="stars">
//         <span>{stargazers}</span>
//         <FaStar />
//       </div>
//       <div className='description'>{content}</div>
//     </div>
//   );