import React from 'react';
import GitHubRepos from '../components/GithubRepos';
import '../styles/Project.css';

const Project = () => {
  return (
    <div className='project'>
      <GitHubRepos />
    </div>
  );
};

export default Project;
