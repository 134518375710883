// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Welcome {
    color: white;
    text-align: center;
    padding: 40px;
}

.t{
    text-align: left;
    padding-left: 40%;
    padding-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Welcome.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":["\n.Welcome {\n    color: white;\n    text-align: center;\n    padding: 40px;\n}\n\n.t{\n    text-align: left;\n    padding-left: 40%;\n    padding-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
