import React from 'react';
import '../styles/Welcome.css';


const Welcome = () => {
  return (
    <div className='Welcome'>
      This website is under construction
      <br></br>
      <div className='t'>
      For now : <br></br>
        - 3 dimensions chess game on webgl page<br></br>
        - GitHub repositories on project page
      </div>
    </div>
  );
};

export default Welcome;
