import React from 'react';
// import RubiksCube from '../components/RubiksCube';
import ChessGL from './Chess/ChessGL';
// import ChessTest from './Chess/ChessTest'

const MyWebGLComponent = () => {

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ChessGL />
    </div>
  );
};

export default MyWebGLComponent;
