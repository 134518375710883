// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.text-intro{
  color: white;
  font-size: 200%;
  text-align: center;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["\n.text-intro{\n  color: white;\n  font-size: 200%;\n  text-align: center;\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
